<template>
  <section id="secQuotations" class="quotations">
    <h2 id="hQuotations">{{ $t("headers.quotations") }}</h2>
    <div class="readFriendlyLayout">
      <blockquote
        cite="http://www.shockwavemagazine.com/index.php?option=com_content&view=article&id=1323%3Aeuropean-metal-union-may-2013&catid=45%3Arap-sheet&Itemid=99"
      >
        "For a debut CD, it's pretty amazing what the band has accomplished,
        from the dark and melodic tendencies throughout the songs to beautiful,
        haunting and atmospheric to downright brutal. I definitely give this
        release a 10 out of 10, and I can't wait to see what else this band is
        going to do next!"
      </blockquote>
      <a
        href="http://www.shockwavemagazine.com/index.php?option=com_content&view=article&id=1323%3Aeuropean-metal-union-may-2013&catid=45%3Arap-sheet&Itemid=99"
        >- shockwavemagazine.com</a
      >

      <blockquote
        cite="http://www.nocleansinging.com/2012/09/23/miscellany-no-47-limb-from-limb-inset-and-to-dust/"
      >
        "To Dust have genuine songwriting and performance skill and State of
        Nothing is looking like an album that needs to be heard from start to
        finish."
      </blockquote>
      <a
        href="http://www.nocleansinging.com/2012/09/23/miscellany-no-47-limb-from-limb-inset-and-to-dust/"
        >- nocleansinging.com</a
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'Quotations',
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 900;
}
</style>
