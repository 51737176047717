<template>
  <div id="about" class="about flexContainerColumn">
    <BandDescription />
    <Quotations />
  </div>
</template>

<script>
import BandDescription from '../components/About/BandDescription.vue';
import Quotations from '../components/About/Quotations.vue';

export default {
  name: 'About',
  components: {
    BandDescription,
    Quotations,
  },
};
</script>
