<template>
  <section id="secAbout">
    <h2 id="hAbout">{{ $t("headers.about-to-dust") }}</h2>
    <div class="readFriendlyLayout">
      <div id="members" class="members">
        <div v-for="(member, index) in members" :key="index">
          {{ member.chosenName }} {{ member.lastName }} -
          {{ $t(`view.about.${member.instrumentsPlayed[0].toLowerCase()}`) }}
        </div>
      </div>

      <img
        class="pictureMembers"
        src="../../assets/images/Bandet.jpg"
        alt="To Dust Band"
      />

      <div id="description">
        <p class="description">
          {{ description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BandDescription',
  data() {
    return {
      description: '',
      members: Array,
    };
  },
  methods: {
    initiateComponent() {
      this.setDescription(this.language);
      this.setMembers();
    },
    async setDescription(language) {
      const { data } = await this.$http.get(
        `${this.$store.getters.toDustApiBaseUrl}/texts/${language}/about`,
      );
      this.description = data.content;
    },
    async setMembers() {
      const { data } = await this.$http.get(
        `${this.$store.getters.toDustApiBaseUrl}/members/active`,
      );
      this.members = data;
    },
  },
  mounted() {
    this.initiateComponent();
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    language(newValue) {
      this.setDescription(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.pictureMembers {
  max-width: 100%;
}

.members {
  text-align: center;
  margin-bottom: 10px;
}

.description {
  white-space: pre-line;
}
</style>
